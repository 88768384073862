#donations_message {
    background:#e7e0ff;
    border-radius: 2px;
    margin:0 0 3rem;
    padding:30px;
    font-size:1.1em;
    border-radius: 10px;
    box-shadow: var(--global-box-shadow);
    color: black;
    
    h3 {
        margin: 3rem 0 1.5rem 0;
        font-size:1.75em;
        border:none;
        text-transform: none;
    }
    
    #db_upgraded {
        color: var(--main_color);
        font-weight: bold;
    }
}
