.file_editor_wrapper {
    padding-top:15px;
    margin-bottom:40px;

    .file_title {
        font-family:var(--font_heading);
        position:relative;
        margin:0;
        padding:0;
        color: var(--main_color);
        font-size:18px;
        display:flex;
        align-items: center;
        
        p {
            padding:0 15px;
            margin:0;
            display:inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 50%;
            overflow: hidden;
        }

        button {
            transform: rotate(0);
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
        }
    }

    &.collapsed {
        margin-bottom: 20px;
        // opacity: .5;

        .file_title {
            button {
                transform: rotate(90deg);
            }
        }

        .file_editor {
            display: none;
            // height: 0px;
            // overflow: hidden;
            // padding: 0;

            // &:after {
            //     content: '';
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 30px;
            //     background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 32%); 
            // }
        }
    }

    .file_editor {
        background:white;
        padding:30px 10px 10px;
        box-shadow: var(--global_box_shadow);
        border-radius: 8px;

        .file_data {
            h3 {
                padding-top:0;
                margin-top:0;
            }
    
            @include bk(xs) {
                background:white;
                margin-bottom:25px;
            }
        }
    
        .textarea_description {
            min-height: 10rem;
        }
    
        .divider {
            margin:40px auto;
        }

        footer {
            border-top: 1px solid #dedede;
            padding-top: 1rem;
            margin-top: 1rem;

            h3 {
                margin: 0 0 .5rem;
                padding: 0;
            }
        }
    }
}
