h1, h2, h3, h4, h5, h6 {
    font-weight:normal;
    font-family: var(--font_heading);
}

h1 {
    color:white;
    font-size:32px;
    line-height:32px;
    margin:15px 0;
}

h2 {
    color:#333333;
    font-size:27px;
    line-height:29px;
    margin:0 0 35px;
    letter-spacing:-1px;
}

h3 {
    font-size:17px;
    line-height:19px;
    padding:0;
    font-weight:900 !important;
    margin-bottom:20px;
    text-transform:uppercase;
}

h4 {
    font-size:13px;
    margin-bottom:20px;
    line-height:18px;
}

h5 {
    font-size:17px;
    margin-bottom:20px;
    line-height:18px;
    
    i {
        margin-right: 8px;
    }
}
